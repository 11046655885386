@import './varibale';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,500&family=Roboto+Mono:wght@200;300;400;500;600;700&family=Roboto:wght@700;900&family=Tajawal:wght@300;400;500;700;800;900&display=swap');

.color-red {
  color: $mainColor !important;
}
.secondColor {
  color: $secondColor !important;
}

a {
  text-decoration: none !important;
}

.btn-primary {
  background-color: $mainColor !important;
}

.btn-outline-primary {
  color: $mainColor !important;

  &:hover {
    background-color: transparent !important;

  }
} 

// table style

thead {
  overflow: hidden;

  >tr {
    border-radius: 15px !important;
    white-space: nowrap !important;

    >th {
      font-family: $fontBase;
      font-style: normal;
      font-weight: 500;
      font-size: 16px !important;
      line-height: 27px !important;

      text-align: center;
      color: #4A4A4A;
      padding: 15px 25px !important;
    }
  }

}

tbody {
  white-space: nowrap !important;

  .img {
    width: 30%;
  }
}

td {
  white-space: nowrap !important;

  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: auto;
    // height: 100px !important;
    gap: 30px;
    font-family: $fontBase;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 32px !important;
    /* identical to box height */
    text-align: center;
    /* Black */
    color: #000000;

    >svg {
      font-size: 20px;
      cursor: pointer;
    }

    svg:first-child {
      color: $colorGreen;
    }

    svg:last-child {
      color: $mainColor;
    }
  }
} 
td {
  white-space: nowrap !important;
} 
 

td {
  >div {
      color: #4e505d !important;
      min-height: 100px !important;
      /* max-width: 160px  !important; */
      // font-size: 1.1rem !important;
      font-weight: 500 !important;
      gap: 1px;  
      .ClientPhone {
          font-size: 15px !important;
          font-weight: normal;
          color: #025585;
      
      }
  }
}

// pagr not found
.app__error {
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.notfound-image {
  @media screen and (max-width:767px) {
    width: 410px;
    
  }
}
.notfound-image{
  width: 100px !important;
}

