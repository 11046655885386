@import '../style/varibale';

body {
  background: $colorLight;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.modal {
  z-index: 999999997 !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $mainColor;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: $mainColor, $alpha: 0.8);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

button {
  // border-color: $mainColor !important;
}

td {
  vertical-align: middle !important;
}

.ClientPhone {
  direction: ltr !important;
}

.p-checkbox {
  width: 45px !important;
}

.dropzone {
  height: 150px;
  border: 2px dashed gray;
  background: white;
  border-radius: 6px;
  cursor: pointer;

  .dz-message {
    font-size: 24px;

    &:focus {
      outline: none;
    }
  }
}

.p-column-header-content {

  text-align-last: center !important;
  display: block !important;
}


.p-selectable-row {
  text-align-last: center !important;
}

.ststus-btn {
  height: 40px !important;
}

.table-img {
  width: 95px !important;
  height: 80px !important;
}

.img-drop {
  height: 90px !important;
  justify-content: space-around !important;
  align-items: center !important;

}

.drop-txt {
  font-size: 25px !important;
}

.drop-cloud {
  width: 40px;
}

.right-card {
  height: 60% !important;
}

.circle-chart {
  display: flex;
  align-self: center;
}

.action-buttons {
  button {
    border: 1px solid #ffffff;
  }
}

.side-image {
  align-self: center !important;
}

.count-data {
  display: flex !important;
  flex-direction: column !important;
}

.blocked-row {

  color: #3B82F6 !important;
  opacity: .5 !important;
}

.unblocked-row {
  color: #bb2124 !important;
}

.blocked-row1 {
  background-color: #3B82F6 !important;
  opacity: .5 !important;
  border: none !important;

}

.unblocked-row1 {
  background-color: #bb2124 !important;
  border: none !important;

}

.profile-container {
  width: 90% !important;
}

.profile-footer {
  width: 90% !important;
  justify-content: center;
  display: flex;
}

.tt {
  width: 88% !important;
}

.role-tr {
  height: 90px !important;
}

.checkbox-design {
  width: 20px !important;
  height: 20px !important;
}

.p-dialog-title {
  text-align: center;

}

.info-input {
  width: 90% !important;
}

.info-label {
  min-inline-size: fit-content !important;
  width: 38% !important;
}

.static-btn {
  width: 80px !important;
  align-self: center !important;


}

.input-panels-complaints {
  align-self: center !important;
}

.edit-label-complaints {
  width: 38% !important;
}

// .check-comment{
//   height: 40px !important;
// }
.profile-inputs {
  align-self: center !important;
  margin-left: 15px;
}

.change-password-label {
  width: 15% !important;
}


.status-and-select {
  place-content: center !important;
}

.select-status {
  width: 110px !important;
}

.dashboard-box {
  background: #fff;
  height: 160px  !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-dashboard-color{
  color: #00B5B8!important;
}
.p-checkbox-box{
  border: 3px solid #0075ea !important;
}
.angleDown{
  align-self: center !important;
}
.images-card{

  input::file-selector-button {
    font-weight: bold;
    color: #ffffff;
    background: #6366F1;
    border: 1px solid #6366F1;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    cursor: pointer;
  }
  
}
.front-image{
  input::file-selector-button {
    font-weight: bold;
    color: #ffffff;
    background: #6366F1;
    border: 1px solid #6366F1;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    cursor: pointer;
  }

}