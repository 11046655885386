@import '../../style/varibale';

.navbar {
    box-shadow: $shadow-navbar;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px !important;

    // .active {
    //     background-color: #F1F1F1 !important;
    //     border-radius: 7px !important;
    // }

    a {
        color: #030303 !important;
    }

    .nav__notification {
        background-color: $mainColor ;
        color: $colorLight;
        border-radius: 5px;
        padding: 8px 10px;
        cursor: pointer;

        &:hover {
            color: $colorLight;
        }
    }
}

.btn__search {
    border: none;
    background-color: $mainColor;
    border-radius: 5px !important;
    position: relative;
    left: 10px;
    z-index: 999;
    padding: 8px 18px;

    svg {
        color: $colorLight;
        font-size: 25px;
    }
}



.input__search {
    padding-left: 20px !important;
    background: #F1F1F1 !important;
    border: none !important;
    outline: none !important;
    border-radius: 5px;
}

.form-control:focus {
    outline: 0;
    box-shadow: none !important;
    border-color: $mainColor
}

.subNav {
    .nav-link {
        font-family: $fontBase;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
    }

    .active {
        color: $mainColor !important;
    }
}

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

.app__navbar-menu {
    width: 35px;
    height: 40px;
    margin-right: 8px !important;
    border-radius: 50%;
    z-index: 2 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: $mainColor;

    svg {
        width: 70%;
        height: 70%;
        color: $colorLight;
    }

    @media screen and (min-width:900px) {
        display: none;
    }
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: relative;
    // left: -135px;
    // top: 55px;
    background-color: rgba((248, 249, 250), var(--bs-bg-opacity)) !important;
    // right: -10px;
    // z-index: 999999999999999999;
    // text-align: initial
}

// .dir-navbar-ar {
//     left: 0 !important;
// }

// .dir-navbar-en {
//     left: -135px !important;
// }

.dropdown-toggle::after {
    display: none !important;
}

.navEnd {
    gap: 18px;

    .chat__icon {
        border-radius: 5px;
        background-color: transparent !important;
        position: relative;

        svg {
            color: #B5B5C3;
        }

        transition: all 0.5s;

        &:hover {
            background-color: #a9defd !important;

            // svg {
            //     color: #f4f4f6 !important;
            // }
        }

        // padding: 20px;
    }

    .bg-success {
        color: rgb(80, 205, 137);
        background-color: #50cd89 !important;
    }

    .bullet-dot {
        width: 4px;
        height: 4px;
        border-radius: 100% !important;
    }

    .bullet {
        display: inline-block;
        border-radius: 6px;
        width: 8px;
        height: 4px;
        flex-shrink: 0;
        z-index: 999999;
    }

    .h-6px {
        height: 6px !important;
    }

    .w-6px {
        width: 6px !important;
    }

    .translate-middle {
        transform: translate(-50%, -50%) !important;
    }

    .start-50 {
        left: 50% !important;
    }

    .top-0 {
        top: 0px !important;
    }

    // Blink
    .animation-blink {
        animation: animationBlink 1s steps(5, start) infinite;
    }

    @keyframes animationBlink {
        to {
            visibility: hidden;
        }
    }
}


.chang__lang {
    .dropdown-toggle {
        background-color: transparent !important;
        color: rgba($color: $colorGrey-1, $alpha: 0.1) !important;
        padding: calc(0.7rem + 1px) calc(1.25rem + 1px) !important;
        // padding: 1.2rem 0.7rem !important;
        border-radius: 0.425rem !important;
        font-size: 0.8rem !important;
        border-radius: 0.4rem;
        outline: none !important;
        border: none !important;
        color: #7E8299 !important;
        background-color: #eff2f5 !important;
        font-weight: 600 !important;
        transition: all 0.5s;
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

        &:hover {

            box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
            color: $mainColor !important;
            border-color: $colorLight;
            background-color: #f1faff !important;
            transition: color 0.2s ease;

        }

        // .my-svg {
        //     stroke: $mainColor  !important;
        // }
    }
}


.dropdown-menu[data-bs-popper] {
    text-align: inherit;
}


.chat__icon .dropdown {
    position: relative;
}

.chat__icon .dropdown::after {
    position: absolute;
    content: '';
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    background-color: #50cd89;
    border-radius: 50%;
    animation: animationBlink 1s steps(5, start) infinite;
}

@keyframes animationBlink {
    to {
        visibility: hidden;
    }
}

.btn__avatar-nav {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-family: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';
    -webkit-font-smoothing: antialiased !important;
    background-color: $mainColor !important;
    color: $colorLight !important;
    font-size: 20px !important;
    font-weight: bold;
    .userName__nav {
        user-select: none;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
        padding-bottom: 4px;
        font-family: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';
        -webkit-font-smoothing: antialiased !important;
        color: #343a40;

        @media screen and (max-width:480px) {
            display: none !important;
        }
    }
}